<template>
    <section class="mb-4">
        <div class="card-pedido px-3 py-2">
            <div class="row mx-0 border-bottom a-center pb-2 mb-2">
                <i class="icon-order-packed f-18" />
                <div class="col-auto px-1 text-general f-15">
                    Recibido
                </div>
                <i class="icon-product f-18 ml-auto" />
                <div class="col-auto px-1 f-600 text-general f-15">
                    {{ formatearDecimalesSeparador(pedido.valor_final,'',0) }} ({{ pedido.total_productos }})
                </div>
            </div>
            <div class="row mx-0 my-2 pb-2 border-bottom">
                <img :src="pedido.leechero_logo" class="obj-cover br-4 border cr-pointer" width="46" height="46" @click="goDetail" />
                <div class="col px-2">
                    <p class="f-14 text-gris2 mt-1 cr-pointer" @click="goDetail">
                        {{ pedido.leechero }}
                    </p>
                    <p class="f-14 text-gris2 cr-pointer" @click="goDetail">
                        {{ pedido.entrega_fecha | helper-fecha('D [de] MMMM') }}, {{ pedido.entrega_horario }}
                    </p>
                </div>
                <div v-if="pedido.estado == 31 || pedido.estado == 33" class="col-2 d-middle text-gris2">
                    <i class="icon-chat cr-pointer" @click="toGoChat" />
                    <span class="f-13">{{ pedido.mensajes }}</span>
                </div>
            </div>
            <div v-if="pedido.estado != 4" class="row mx-0 a-center">
                <i :class="`${estadoReclamo.icono} f-18`" :style="`color: ${estadoReclamo.color}`" />
                <div class="col-auto px-2 f-15" :style="`color: ${estadoReclamo.color}`">
                    {{ estadoReclamo.nombre }}
                </div>
            </div>
            <div v-else class="row mx-0 a-center">
                <div class="btn-reclamo cr-pointer f-500 f-14 px-3" @click="irReportarReclamo(pedido.reclamoValido, 31)">
                    Iniciar Reclamo al {{ $config.vendedor }}
                </div>
            </div>
        </div>
        <div v-if="pedido.estado != 4" class="row mx-0 my-2">
            <div class="btn-reclamo cr-pointer f-500 f-14 px-3" @click="changeEstado">
                {{ estadoReclamo.boton }}
            </div>
        </div>
        <modal-reportar-reclamo ref="modalReportarReclamo" @update="$emit('update')" />
        <modal-realizar-soporte ref="modalRealizarSoporte" @update="$emit('update')" />
        <modal-chat-pedido ref="modalChat" :titulo="pedido.estado == 31 ? 'Chat de reclamo activo' : pedido.estado == 33 ? 'Chat de soporte' : ''" @update="updateCantMessages" />
        <modal ref="modalConfirmar" :titulo="modalConfirmar.titulo" adicional="Finalizar" @adicional="putChangeEstadoPedido">
            <div class="row mx-0">
                <div class="col text-center text-general f-15">
                    {{ modalConfirmar.mensaje }}
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Service from '@/services/ayuda/ayuda'
export default {
    components: {
        modalReportarReclamo: () => import('../../pedidos/partials/modalReportarReclamo'),
        modalRealizarSoporte: () => import('../../pedidos/partials/modalRealizarSoporte'),
    },
    props: {
        pedido:{
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            modalConfirmar: {
                titulo: '',
                mensaje: ''
            },
            finalizarTipo: null

        }
    },
    computed:{
        estadoReclamo(){
            return this.identificarEstado();
        },
    },
    methods: {
        async putChangeEstadoPedido(){
            try {
                let estado = 0;
                switch(parseInt(this.pedido.estado)){
                case 31:
                    estado = 32;
                    break;
                case 33:
                    estado = 34;
                    break;
                }
                const model = {
                    estado: estado
                };
                const {data} = await Service.putEstadoPedido(this.pedido.id, model)
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('update');
                return this.$refs.modalConfirmar.toggle();
            } catch(e){
                this.errorCatch(e)
            }
        },

        irReportarReclamo(reclamoValido, estado){
            if(!reclamoValido){
                return this.notificacion('Lo sentimos', 'La fecha para realizar un reclamo ha expirado', 'warning');
            }
            this.$refs.modalReportarReclamo.toggle(this.pedido.id, estado)
        },
        
        changeEstado(){
            switch(parseInt(this.pedido.estado)){
            case 4:
                return this.$refs.modalReportarReclamo.toggle(this.pedido.id, estado);
            case 31:
                this.asignarMensajeModalConfirmar('Finalizar reclamo', '¿Desea finalizar este reclamo?');
                return this.$refs.modalConfirmar.toggle();
            case 32:
                return this.$refs.modalRealizarSoporte.toggle(this.pedido.id)
            case 33:
                this.asignarMensajeModalConfirmar('Finalizar soporte', '¿Desea finalizar este soporte?');
                return this.$refs.modalConfirmar.toggle();
            }
        },

        asignarMensajeModalConfirmar(tipo, mensaje){
            this.modalConfirmar.titulo = tipo;
            this.modalConfirmar.mensaje = mensaje;
        },

        identificarEstado(){
            switch(parseInt(this.pedido.estado)){
            case 31:
                return {nombre:'Reclamo en proceso', icono: 'icon-alert-circle-outline', color: 'red', boton: 'Finalizar reclamo'};
            case 32:
                return {nombre:'Reclamo finalizado', icono: 'icon-alert-circle-outline', color: 'red', boton: 'Iniciar soporte Leeche'};
            case 33:
                return {nombre:'Soporte en proceso', icono: 'icon-suport', color: 'black', boton: 'Finalizar soporte'};
            }
        },

        toGoChat(){
            let object = {idPedido: this.pedido.id, estado: this.pedido.estado_soporte};
            this.$refs.modalChat.toggle(object);
        },

        updateCantMessages(value){
            this.pedido.mensajes = value;
        },
        goDetail(){
            this.$router.push({name:'pedidos.ver', params:{ idPedido:this.pedido.id }});
        },
    }
}
</script>
<style lang="scss" scoped>
.card-pedido{
    min-height: 100px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
}
.btn-reclamo{
    border: 1px solid #DFE4E8;
    border-radius: 8px; 
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    height: 36px;
}
</style>